@import "variable.scss";



.left-line {
    border-left: 1px solid #e1e1e1;
    position: relative; 
    &:before {
        content: "";
        position: absolute;
        height: 30px;
        width: 1px;
        background: #e1e1e1;
        top: -30px;
        left: -1px;
    }
}



.event-sideber{
   
    h4{
        color: #18212c;
        font-size: 18px;
        font-weight: bold;
        margin-bottom:25px;
    }
    .event-sideber-img{
        margin-bottom: 45px;
        a{
            margin-right: 5px;
        }
    }
    .event-sideber-category{
        li{
            margin-bottom: 10px;  
            a{
                color: #847577;
                font-size: 14px;
                font-weight: 500;                
            }
        }
    }
    .event-sideber-search {
        margin-bottom: 50px;
        form{
            position: relative;
            input{
                border-radius: 25px;
                font-size: 12px;              
            }
            .form-control{
                padding: 10px 50px 10px 20px;
            }
        }
    }
    .event-sideber-search.setting{
        form{
            position: relative;
            input{
                border-radius: 0;
            }
        }
    }
    .media{
        margin-bottom: 5px;
        .media-img {
            position: relative;
        }
        .media-body{
            h3{
                color: #18212c;
                font-size: 16px;
            }
        }
    }

    .sideber-meta{
        margin-bottom: 40px;  
    }
    .btn{
        padding: 10px 25px;
        font-size: 12px;
        font-weight: 500;
        margin: 3px;
        &:hover {
            color: $white;
        }
    }
    .btn-secondary {
        color: #18212c;
        background-color: #e1e1e1;
        border-color: #e1e1e1;
        font-weight: bold;
        font-size: 12px;
        &:hover{
            color: #fff;
            background-color: #5a6268;
            border-color: #545b62;
        }
    }
    .btn-outline-secondary {
        color: #18212c;
        border-color: #e1e1e1;
        
    }
    .sideber-social{
        h4{
            color: #18212c;
            font-size: 14px;
            display: inline-block;
        }
        i {
            margin-left: 20px;
            color: #847577
        }
    }
}
    

@media screen and (max-width:1199px){

    .left-line {
        border: none;
        &:before {
            content: none;
        }
    }

    .event-sideber {
        margin-top: 30px;
    }

}/*max-width:1199px*/
