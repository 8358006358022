// Font Variable
$pf: 'Montserrat',
sans-serif;
// Color Variable
$primary: #e53632;
$secondary: #978DED;
$success: #71C565;
$info: #F5A3E3;
$danger: #ff5722;
$warning: #FFC939;
$dark: #6a707e;
$light: #f8f9fa;
$muted: #abafb3;


$btn-primary: #E1E1E1;
$btn-success: #C92925;
$btn-danger: #18212C;
$btn-hover: #B9B9B9;
///////////////
// Solid Color
///////////////
$white: #fff;
$black: #000;
////////////////////////
// Light Version Color
////////////////////////
$ctd: #454545;
$ctl: #678098;
$border: #e7e7e7;
$bg: #f3f3f3;
/////////////
// Social
/////////////
$facebook: #3b5998;
$twitter: #1da1f2;
$youtube: #cd201f;
$google-plus: #db4439;
$linkedin: #007bb6;
$instagram: #c32aa3;
$pinterest: #bd081c;
$google: #4285f4;
$snapchat: #fffc00;
$whatsapp: #25d366;
$tumblr: #35465d;
$reddit: #ff4500;
$spotify: #1ed760;
$yahoo: #430297;
$dribbble: #ea4c89;
$skype: #00aff0;
$quora: #aa2200;
$vimeo: #1ab7ea;