@import "variable.scss";

.event-msg-left{

    .custom-checkbox .custom-control-input:checked~.custom-control-label::before {
        background-color: #e53632;
    }
    .msg-single,
    .msg-navigation{
        label{
            color: #18212c;
            font-size: 14px;
            font-weight: 600;
            &::before{
                height: 17px;
                width: 17px;
                border: 1px solid #e1e1e1;
                border-radius: 100%;
                background: transparent;

            }
            &::after{
                left: -1px;
                top: .20rem;
            }
            
        }
    } 
    .msg-navigation{
        margin-bottom: 30px;
    }
 
    .msg-btn {
        background: #f7f7f7;
        border: none;
        font-size: 12px;
        font-weight: 600;
        color: #847577;
        padding: 12px 20px;
        margin: -10px 0 0 15px;
    }

    .msg-single{
        padding: 20px;
        &.active{
            background: #f7f7f7;
            border-color: #e1e1e1;
        }
        .custom-control {
            display: inline-block;
            float: left;
        }
        .custom-checkbox {
            margin-top: 15px;
        }
        .media-body{
            overflow: hidden;
            h3{
                font-size: 16px;
                font-weight: bold;
                color: #18212c;
                margin-bottom: 0px;
            }
            p{
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                margin-bottom: 0;
                color: #847577;
            }
        }

        .time{
            text-align: right;
            h5{
                font-size: 12px;
                color: #18212c;
                font-weight: 600;
            }
            span{
                padding: 5px 8px;
                display: block;
                height: 20px;
                width: 20px;
                border-radius: 50%;
                color: #fff;
                line-height: 12px;
                float: right;
                font-size: 10px;
            }
        }
        
    }
    

}

@media screen and (max-width:767px){

    .msg-single{
        .col-auto{
            width: 100%;
        }
    }
    .msg-navigation{
        .col-auto {
            display: block;
            margin-bottom: 15px;
        }
        label{
            font-size: 10px!important;
        }
        // .msg-btn {
        //     display: block;
        //     width: 100%;
        //     margin-top: 15px!important;
        // }
        .msg-btn {

            padding: 8px 10px;
            margin: -10px 0 0 10px;
        }
        
    }

}/*max-width:767px*/

.search-2 {
    button[type="submit"] {
        position: absolute;
        border: 0;
        background-color: transparent;
        right: 15px;
        top: 13px;
    }
}