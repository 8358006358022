@import "variable.scss";

.event-modal{
    .modal-dialog {
        max-width: 970px;
    }

    .modal-right{

        h3{
            font-size: 18px;
            color: #18212c;
            margin-bottom: 0;
        }
        .media{
            padding: 50px 30px 30px 30px;
        }
        .media-body{
            
            p{
                color: #e53632;
                margin-bottom: 25px;
            }
            i{
                margin-right: 5px;
            }
            .btn{
                padding: 15px 25px;
                font-size: 12px;
                font-weight: bold;
                margin-right: 5px;
                margin-bottom: 5px;
            }

        }

        .modal-about-area{
            padding: 30px;
            h3{
                font-weight: bold;
    
            }
            p,i{
                font-size: 14px;
                color: #847577;
            }
            p{
                margin: 25px 0;
            }
            i{
                margin-right: 15px;
                &:hover{
                    color: #1da1f2;
                }
            }
        
        }

        .modal-tags{
            padding: 30px 30px 50px;
            h3{
                margin-bottom: 25px;
            }
            .btn{
                padding: 10px 15px;
                font-size: 12px;
                margin-right: 5px;
                margin-bottom: 5px;
            }
            .btn-outline-secondary {
                color: #18212c;
                background-color: #f9f9f9;
                border-color: #e1e1e1;
                &:hover{
                    background-color: #e6e6e6;
                }
            }
            
        }

        .modal-footer-area{
            border-right: 1px solid #e1e1e1;
            padding: 25px 30px;
            p{
                margin-bottom: 0px;
                color: #18212c;
                i{
                    color: #e53632;
                    margin-right: 5px;
                }
            }
            a{
                color: #b9b9b9;
                margin-right: 10px;
            }
        }

        .modal-tags,
        .modal-about-area,
        .media{
            border-bottom: 1px solid #e1e1e1;
            border-right: 1px solid #e1e1e1;

        }

    }

    .modal-left {
        // padding-left: 30px;
        .tab-content {
          padding: 0 20px;
        }
        
        .nav-item{
            a{
                color: #b9b9b9;
                font-size: 14px;
                font-weight: 600;
                padding: 25px;
                &.active{
                    color: #18212c;
                }
            }
        }
        .media{
            border: 1px solid #e1e1e1;
            margin-bottom: 20px;
            
            .media-body{
                padding: 10px 0;
                h3{
                    color: #18212c;
                    font-size: 18px;
                    margin: 2px;
                }
                p {
                    color: #e53632;
                    margin-bottom: 7px;
                    font-size: 12px;
                }
                i{
                    margin-right: 5px;
                }
                span{
                    color: #847577;
                    font-size: 12px;
                }
            }
        }

    }


}

.creat-event{
    .modal-dialog {
        max-width: 970px;
    }
    .modal-header{
        padding: 30px;
        padding-bottom: 0;
        border: none;
        h2{
            margin-top: 20px;
        }
    }
    .modal-body{
        padding: 30px;
        padding-top: 0;
        .form-control{
            font-size: 12px;
        }
        textarea.form-control {
                height: 100px;
        }
        .b-r-0{
            border-right: 0px;
        }  
    }
    select{
        height: 50px!important;
    }
    label{
        color: #18212c;
        font-size: 14px;
        font-weight: 600;
    }   
    .input-group-text {
        background: transparent;
        border-color: #e1e1e1;
    }
    .search-area{
        position: relative;
        i {
            position: absolute;
            top: 50%;
            -webkit-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
            transform: translateY(-50%);
            right: 15px;
            cursor: pointer;
        }
    } 

}

.evemt-view{
    .modal-dialog {
        max-width: 1110px;
    }
    .card{
        border: none;
    }
    .card-img-overlay{
        top: initial;
    }
    .box-0{
        box-shadow: none;
    }
    .event-card {
        position:  relative;
        padding-bottom:  83px;
        // margin-bottom: 0;
    }
    .card-header{
        p{
            font-size: 14px;
        }
    }
    .card-sponsor{
        border-top: 1px solid #e1e1e1;
        border-bottom: 1px solid #e1e1e1;
    }
    .char-type {
        padding: 15px;
        width: 100%;
        position: absolute;
        bottom:  0;
        -webkit-box-shadow: 0px 0px 10px rgba(0,0,0,.8);
        -moz-box-shadow: 0px 0px 10px rgba(0,0,0,.8);
        box-shadow: 0px 0px 10px rgba(0,0,0,.8);
        .btn {
            padding: 10px 15px;
            font-size: 12px;
            font-weight: bold;
        }
        input {
            font-size: 12px;
        }
    }
    

}

// .event-sideber .event-sideber-search form 

@media screen and (max-width:1199px){

    .evemt-view,
    .event-modal{
        .p-r-0{
            padding-right: 15px!important;
        }
        .p-l-0{
            padding-left: 15px!important;
        }


    }
}/*max-width:1199px*/

@media screen and (max-width:480px){
    .event-modal{
        .modal-left {
            .nav-item{
                a {
                    color: #b9b9b9;
                    font-size: 13px;
                    font-weight: 500;
                    padding: 10px; 
                }
            } 
        }
    }
}/*max-width:480px*/