@import "variable.scss";
* {
    outline: none;
}

body {
    // background : -bg;
    font-family: $pf;
    margin: 0;
    overflow-x: hidden;
    font-size: 14px;
    height: 100%;
    font-weight: 500;
}

html {
    position: relative;
    // height: 100%;
}

#main-wrapper {
    opacity: 0;
    transition: all 0.25s ease-in;
}

#main-wrapper {
    &.show {
        opacity: 1;
    }
}

a {
    transition: all 0.4s ease-in-out;
}

a:focus,
a:hover {
    text-decoration: none;
}

a.link {
    color: $ctd;
}

a.link:focus,
a.link:hover {
    color: $primary;
}

ul {
    padding: 0;
}

li {
    list-style: none;
}

img {
    max-width: 100%;
}

.img-responsive {
    width: 100%;
    height: auto;
    display: inline-block;
}

.img-rounded {
    border-radius: 4px;
}

.img-flag {
    height: 15px;
    width: 18px;
}

.img-full {
    width: 100%;
}

.mdi-set,
.mdi:before {
    line-height: initial;
}

::selection {
    color: $white;
    background-color: $btn-success;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 600;
}

h1 {
    line-height: 40px;
    font-size: 36px;
}

h2 {
    line-height: 36px;
    font-size: 24px;
}

h3 {
    line-height: 30px;
    font-size: 21px;
}

h4 {
    line-height: 22px;
    font-size: 18px;
}

h5 {
    line-height: 18px;
    font-size: 16px;
    font-weight: 400;
}

h6 {
    line-height: 16px;
    font-size: 14px;
    font-weight: 400;
}

.display-5 {
    font-size: 3rem;
}

.display-6 {
    font-size: 36px;
}

.box {
    border-radius: 4px;
    padding: 10px;
}

.img-circle {
    border-radius: 100%;
}

.content-body {
    padding: 30px 0px;
}

// @media (max-width: 767px) {
//     .content-body {
//         .container-fluid {
//             padding: 15px 15px 0px;
//         }
//     }
// }
.c-pointer {
    cursor: pointer;
}

.rotate-90 {
    transform: rotate(90deg);
}

.jqstooltip {
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
}

.l-border-1 {
    border: 1px solid -border;
}

.d-border-1 {
    border: 1px solid -border;
}

.footer {
    p {
        font-size: 12px;
    }
}

.color-primary {
    color: $primary;
}