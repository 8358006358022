@import "variable.scss";
.card-title {
    font-weight: 700;
    text-transform: uppercase;
    font-size: 16px;
}

.card {
    border: 0px;
    margin-bottom: 30px;
    box-shadow: 0px 0px 30px #ddd;
}

.no-card-border {
    .card {
        border: 0px;
        box-shadow: none;
        .card-body {
            padding: 0px;
        }
    }
}