@import "variable.scss";
.my-rule {
    height: 200px;
    width: 100%;
    background: red;
}

.seat-content {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%);
    h2 {
        margin-bottom: 0px;
        line-height: 1;
        font-weight: 500;
        color: #E53632;
    }
    span {
        color: #E53632;
        font-size: 13px;
    }
}